<template>
  <div class="cr-card-detail-option" v-if="show">
    <v-icon>
      mdi-account-outline
    </v-icon>

    <span v-if="!isEdit" class="text-truncate">
      {{ name }}
    </span>

    <v-row v-else>
      <v-col cols="6" sm="5"
        ><TextField label="성" :value.sync="lastName"
      /></v-col>
      <v-col cols="6" sm="5"
        ><TextField ref="name" isRequired label="이름 *" :value.sync="firstName"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import TextField from "@/contact/views/components/list/common/TextField.vue";

export default {
  components: { TextField },
  data({ $store: { getters: g } }) {
    const { firstName, lastName } = g["cardDetail/detail"];
    return { firstName: firstName ?? "", lastName: lastName ?? "" };
  },
  computed: {
    ...mapGetters("cardDetail", ["isEdit", "detail"]),
    name() {
      const { firstName, lastName } = this;
      return `${lastName} ${firstName}`;
    },
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      return !isBlank(this.firstName + this.lastName);
       */
    }
  },
  watch: {
    isEdit(isEdit) {
      if (isEdit) return;

      this.firstName = this.detail?.firstName ?? "";
      this.lastName = this.detail?.lastName ?? "";
    }
  },
  methods: {
    getData() {
      if (!this.isEdit) return;

      let { firstName, lastName } = this;
      lastName = lastName?.trim() || "";
      firstName = firstName?.trim() || "";

      if (isBlank(firstName)) {
        this.$refs.name?.$refs?.textfield?.focus();
        throw new Error("이름을 입력해주세요.");
      }
      return { lastName, firstName };
    }
  }
};
</script>
